.Upcoming {
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    text-align: center;
    width: inherit;
    vertical-align: middle;
    border-width:.3vh !important;
}

.inner {
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    text-align: center;
    font-family: sans-serif;
}

.dropContianer {
    border: 1px dashed #C7C7C7;
    background-color: #E1E1E1;
    border-radius: 10px;
}

.raceControllerButton {
    flex: 1 10vh;
}

.raceControllerButtonDeck {
    display: flex;
}

.controlTableTitle {
    font-size: calc(1vw + 1vh);
    text-align: center;
    white-space: nowrap;
    height: auto;
    /* height: 6vw; */
    padding-top: auto;
    padding-bottom: auto;
}
