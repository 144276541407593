.SignIn {
    text-align: center;
    font-size: 3vh;
}

.SignInButton {
    min-height: 8vh !important;
    min-width: 30vh !important;
    font-size: 3vh !important
}
