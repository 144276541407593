.background {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/meet-status.appspot.com/o/images%2FpoolBackground.jpg?alt=media&token=a4b19348-3085-4d3c-b1f2-df7e744e8b4d');
    background-size: cover;
    background-position: 50% 50%;
}

.headingtext {
    text-align: center;
}

.justify-content-md-center {

}

.getStarted {
    font-size: 8vh;
    color: white;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
}

.payAsYouGo {
    font-size: 4vh;
    color: white;
    font-weight: 400;   
}

a.payAsYouGo:hover {
    color: lightblue;
}

.getStartedButton {
    background-color: white !important;
    font-size: 2.5vh !important;
    border-width: 0 !important;
    box-shadow: 0;
}

.getStartedButton:hover {
    color: black !important;
    box-shadow: unset !important;
}

.getStartedButton::selection {
    box-shadow: unset !important;
}
