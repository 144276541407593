.Current {
    margin-top: .5vmin;
    margin-bottom: .5vmin;
    line-height: normal;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    border-width:0.5vmin !important;
    flex: 2;
    flex-basis: 60vh;
    margin-left: 0.5vmin;
    margin-right: 0.5vmin;
}

.Current-race-text {
    font-size: calc(13.5vmin + 2vh);
    min-height: 60%;
    max-height: 70%;
    /* white-space: nowrap; */
    /* position: absolute; */
    /* top: 50%; left: 50%; */
    /* transform: translate(-50%,-50%); */
}

.heat-name {
    min-height: 30%;
    font-size: calc(4.0vmin + .4vh);
}

.Current-heading {
    font-size: 4vmin;
    font-weight: normal;
}

.Current-race {
    width: 50vw;
    height: 50vh;
}

.top-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    height: 100%;
}

.bottom-deck {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    height: 100%;
}

.upcoming-flex {
    border-width:.6vmin !important;
    margin: .3vmin;
    padding-top: .3vmin;
    padding-bottom: .3vmin;
    padding-left: .6vmin;
    padding-right: .6vmin;
    flex: 2 auto;
    text-align: center;
    font-size: calc(7.9vmin + 0.8vh);
}

.Upcoming {
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    text-align: center;
    width: inherit;
    vertical-align: middle;
    border-width:.3vh !important;
}

.Stroke-text {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    line-height: normal;
    font-weight: normal;
}
