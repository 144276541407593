.testMastercontainer {
  height: 100vh;
  width: 100vw;
  background-color: blue;
}


.beta {
    text-align: center;
    color: white;
}

.beta-link {
    text-align: center;
    color: white;
    text-decoration: underline;
}

.beta-link:hover {
  color: lightgray;
}

.Loading {
    text-align: center;
}

.Upcoming-divider {
    text-align: center;
    font-size: calc(5.5vmin + 1vh);
    margin-top: 0.2vmin;
    margin-bottom: 0.2vmin;
    line-height: normal;
    font-weight: bold;
}

.Upcoming-divider-deck {
    margin-top: calc(0.5vmin + 0.5vh);
    margin-bottom: calc(0.5vmin + 0.5vh);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  min-height: 40vmin;
  pointer-events: none;
}

.Current-event-main {
    padding: 0px;
    margin: 0px;
    text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
